<template>
  <div
    style="display: grid; grid-template-rows: auto 1fr; height: 100%"
    class="container-xs"
  >
    <AppHeader back-to="/profile/payments" />
    <p class="app-subtitle" style="margin-top: 2rem">ADD PAYPAL</p>

    <x-button
      id="paypal-signup"
      type="large"
      style="align-self: center; justify-self: center"
      @click="paypalSignup"
    >
      {{ $t('settings.connect-your-paypal') }}
      <i style="margin-left: 1rem" class="x-paypal-logo-img image-center"></i>
      <i style="margin-left: 1rem" class="x-paypal-img image-center"></i>
    </x-button>

    <modal
      ref="successModal"
      style="height: 100vh; width: 100vw; text-align: center"
      x-class="container-expand"
      x-style="display: grid; height: 100vh; width: 100vw; align-content: center; justify-content: center; position: relative"
      @hide="$router.push('/settings')"
    >
      <x-close-button
        style="
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 1rem;
          margin-right: 1rem;
        "
        @click="$refs.successModal.hide()"
      />
      <h3 class="fs-48" style="font-family: GTSuper">
        {{ $t('settings.success') }}
      </h3>

      <div
        style="
          display: grid;
          gap: 1.5rem;
          margin-top: 3rem;
          justify-items: center;
        "
      >
        <div class="x-img image-center"></div>
        <p style="width: 400px; margin-top: 1rem">
          {{ $t('settings.has-been-added') }}
        </p>
        <x-button>Continue</x-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'AddPayPal',
  mounted() {
    if (this.$route.query.code && this.$route.query.scope) {
      this.$store
        .dispatch('UserModule/addPaypal', {
          token: this.$route.query.code,
          scope: this.$route.query.scope,
          is_default: true,
        })
        .then(() => {
          if (this.isAddingSearchPayment) {
            this.setIsAddingSearchPayment(false)
            this.$router.push('/search/checkout')
          } else this.$refs.successModal.show()
        })
    }
  },
  computed: mapState('PersistModule', ['isAddingSearchPayment']),
  methods: {
    ...mapMutations('PersistModule', ['setIsAddingSearchPayment']),
    paypalSignup() {
      this.$store.dispatch('UserModule/paypalSignup').catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.x-img {
  background-image: url('~@/assets/paypal.jpg');
  width: 156px;
  height: 40px;
}
.x-paypal-logo-img {
  background-image: url('~@/assets/paypal-logo.png');
  width: 20px;
  height: 24px;
}
.x-paypal-img {
  background-image: url('~@/assets/paypal.png');
  width: 90px;
  height: 24px;
}
.x-tagline {
  text-align: center;
  font-size: 0.9375rem;
}
</style>
